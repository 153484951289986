<template>
	<div class="login row-me">
		<div class="leftBox">
			<div class="texts">
				<img src="../assets/img/title.png">
			</div>
		</div>
		<div class="rightBox">
			<!-- 这个是登录 -->
			<div class="nesText" v-if="mipIndex == 0">
				<div class="tabNav row-me row-center column-center" style="padding-top: 130px;">
					<div class="tabBox column-me column-center row-center" style="margin-right: 60px;"
						@click="tabClick(0)">
						<div class="font-size24 color9D9FA3" :class="{'tabAdd':tabIndex == 0}">账号登录</div>
						<div class="border" v-if="tabIndex == 0"></div>
						<div class="border1" v-if="tabIndex == 1"></div>
					</div>
					<div class="tabBox column-me column-center row-center" @click="tabClick(1)">
						<div class="font-size24 color9D9FA3" :class="{'tabAdd':tabIndex == 1}">验证码登录</div>
						<div class="border" v-if="tabIndex == 1"></div>
						<div class="border1" v-if="tabIndex == 0"></div>
					</div>
				</div>
				<div class="row-me row-center divBox">
					<img src="../assets/img/icon_sjh.png" alt="" class="iconImg posAbsolute" />
					<el-input placeholder="请输入账号" prefix-icon="el-icon-search" v-model="form.account" class="loginIpt"
						v-if="tabIndex == 0">
					</el-input>

					<el-input placeholder="请输入手机号"
						onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
						onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
						prefix-icon="el-icon-search" maxlength="11" v-model="form.mobile" class="loginIpt"
						v-if="tabIndex == 1">
					</el-input>
				</div>
				<div class="row-me row-center divBox" v-if="tabIndex == 0">
					<img src="../assets/img/icon_mima.png" alt="" class="iconImg posAbsolute" />
					<el-input placeholder="请输入密码" prefix-icon="el-icon-search" v-model="form.password" class="loginIpt"
						type="password">
					</el-input>
				</div>
				<div class="row-me row-center divBox" v-if="tabIndex == 1">
					<img src="../assets/img/icon_yzm.png" alt="" class="iconImg posAbsolute" />
					<el-input placeholder="请输入验证码" maxlength="8" prefix-icon="el-icon-search" v-model="form.code"
						class="loginIpt">
					</el-input>
					<div class="codeBox">
						<span v-show="show" @click="getCode" class="getCode">获取验证码</span>
						<span v-show="!show" class="count">{{count}}s后重新获取</span>
					</div>
				</div>
				<div class="row-me row-center space-between txtBox">
					<div class="font-size14 txtDiv" @click="loginClick">注册</div>
					<div class="font-size14 txtDiv" v-if="tabIndex == 0" @click="forgetPassword">忘记密码</div>
				</div>

				<el-button class="logins" @click="clickLogin">登录</el-button>
			</div>
			<!-- 注册 -->
			<div class="nesText" v-if="mipIndex == 1" style="margin: 170px 390px;">
				<p>注册</p>
				<div class="row-me row-center divBox">
					<img src="../assets/img/icon_sjh.png" alt="" class="iconImg posAbsolute" />
					<el-input placeholder="请输入手机号"
						onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
						onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
						prefix-icon="el-icon-search" maxlength="11" v-model="formLogin.mobile" class="loginIpt">
					</el-input>
				</div>
				<div class="row-me row-center divBox">
					<img src="../assets/img/icon_yzm.png" alt="" class="iconImg posAbsolute" />
					<el-input placeholder="请输入验证码" maxlength="8" prefix-icon="el-icon-search" v-model="formLogin.code"
						class="loginIpt">
					</el-input>
					<div class="codeBox">
						<span v-show="show" @click="getCode" class="getCode">获取验证码</span>
						<span v-show="!show" class="count">{{count}}s后重新获取</span>
					</div>
				</div>
				<div class="row-me row-center divBox">
					<img src="../assets/img/icon_yhm.png" alt="" class="iconImg posAbsolute" />
					<el-input placeholder="请输入用户名" prefix-icon="el-icon-search" v-model="formLogin.account"
						class="loginIpt">
					</el-input>
				</div>
				<div class="row-me row-center divBox">
					<img src="../assets/img/icon_mima.png" alt="" class="iconImg posAbsolute" />
					<el-input placeholder="请输入密码" prefix-icon="el-icon-search" v-model="formLogin.password"
						class="loginIpt" type="password">
					</el-input>
				</div>
				<div class="row-me row-center space-between txtBox">
					<div class="font-size14 " @click="goEnter">已有账号 <span class="txtDiv">去登录</span></div>
				</div>
				<el-button class="logins" @click="nextStep">下一步 完善企业资料</el-button>
			</div>

			<!-- 完善企业信息 -->
			<div class="nesText nesTextW" v-if="mipIndex == 2" style="">
				<div class="row-me row-center divP" @click="clickEVPI(0)">
					<img src="../assets/img/btn_back.png" alt="" sizes="" srcset="" class="returnImg">
					<p>完善企业信息</p>
				</div>
				<div class="row-me row-center divBox">
					<div class="tableTxt">
						<span style="color: #EB4B4D;">*</span>
						<span>公司名称</span>
					</div>
					<el-input placeholder="请输入公司名称" v-model="evpiForm.company_name" class="loginIpt">
					</el-input>
				</div>
				<div class="row-me row-center divBox">
					<div class="tableTxt tableTxtH" style="">
						<span style="color: #EB4B4D;">*</span>
						<span>行业</span>
					</div>
					<el-select placeholder="请选择行业" class="loginSelect" filterable clearable v-model="evpiForm.trade_id"
						@onchange="selectChange">
						<el-option v-for="item in industryArray" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="row-me row-center divBox">
					<div class="tableTxt">
						<span style="color: #EB4B4D;">*</span>
						<span>联系人</span>
					</div>
					<el-input placeholder="请输入联系人" v-model="evpiForm.contacts" class="loginIpt">
					</el-input>
				</div>
				<div class="row-me row-center divBox">
					<div class="tableTxt">
						<span style="color: #EB4B4D;">*</span>
						<span>邮箱</span>
					</div>
					<el-input placeholder="请输入邮箱" v-model="evpiForm.email" class="loginIpt">
					</el-input>
				</div>
				<div class="row-me row-center divBox" style="margin-bottom: 0;">
					<div class="tableTxt">
						<span style="color: #EB4B4D;">*</span>
						<span>地址</span>
					</div>
					<el-input placeholder="请输入地址" v-model="evpiForm.address" class="loginIpt">
					</el-input>
				</div>
				<el-button class="logins btnEVPI" style="" @click="clickEVPI(1)">确定</el-button>
			</div>

			<!-- 忘记密码 -->
			<div class="nesText" v-if="mipIndex == 3" style="margin: 170px 390px;">
				<div class="row-me row-center divP" @click="clickForgetPassword(0)">
					<img src="../assets/img/btn_back.png" alt="" sizes="" srcset="" class="returnImg">
					<p>密码重置</p>
				</div>
				<div class="row-me row-center divBox">
					<img src="../assets/img/icon_sjh.png" alt="" class="iconImg posAbsolute" />
					<el-input placeholder="请输入手机号"
						onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
						onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
						prefix-icon="el-icon-search" maxlength="11" v-model="forgetForm.mobile" class="loginIpt">
					</el-input>
				</div>
				<div class="row-me row-center divBox">
					<img src="../assets/img/icon_yzm.png" alt="" class="iconImg posAbsolute" />
					<el-input placeholder="请输入验证码" maxlength="8" prefix-icon="el-icon-search" v-model="forgetForm.code"
						class="loginIpt">
					</el-input>
					<div class="codeBox">
						<span v-show="show" @click="getCode" class="getCode">获取验证码</span>
						<span v-show="!show" class="count">{{count}}s后重新获取</span>
					</div>
				</div>
				<div class="row-me row-center divBox">
					<img src="../assets/img/icon_mima.png" alt="" class="iconImg posAbsolute" />
					<el-input placeholder="请输入密码" prefix-icon="el-icon-search" v-model="forgetForm.password"
						class="loginIpt" type="password">
					</el-input>
				</div>
				<div class="row-me row-center divBox">
					<img src="../assets/img/icon_mima.png" alt="" class="iconImg posAbsolute" />
					<el-input placeholder="请输入新密码" prefix-icon="el-icon-search" v-model="forgetForm.password1"
						class="loginIpt" type="password">
					</el-input>
				</div>
				<el-button class="logins" style="margin-top: 50px;" @click="clickForgetPassword(1)">确定</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	// import {
	// 	adminLogin
	// }
	// from "@/api/login/login.js"
	export default {
		components: {

		},
		data() {
			return {
				// 判断走哪个弹层
				mipIndex: 0, //0登录 1注册 2企业资料 3忘记密码
				// 登录相关
				form: {
					account: "",
					mobile: "",
					password: "",
					code: "",
				},
				tabIndex: 0,
				// 倒计时开始
				show: true,
				count: '',
				timer: null,
				// 注册相关
				formLogin: {
					mobile: '',
					code: '',
					account: '',
					password: '',
				},
				// 忘记密码
				forgetForm: {
					mobile: '',
					code: '',
					password: '',
					password1: '',
				},
				// 完善企业信息
				evpiForm: {
					company_name: '',
					trade_id: '',
					contacts: '',
					email: '',
					address: '',
					id: '',
				},
				industryArray: [], //行业
			}
		},
		// html加载完成之前
		created() {

		},
		// html加载完成后执行。
		mounted() {

		},
		// 事件方法执行
		methods: {
			tabClick(index) {
				this.tabIndex = index;
			},
			// 获取验证码
			getCode() {

				// mipIndex: 0, //0登录 1注册 2企业资料 3忘记密码
				var mobile;
				if (this.mipIndex == 0) {
					if (this.form.mobile == '') {
						this.$message.error("手机号不能为空！")
						return false;
					}

					if (!(/^1[3456789]\d{9}$/.test(this.form.mobile))) {
						this.$message.error("手机号码有误，请重新输入！")
						return false;
					}
					mobile = this.form.mobile;

				} else if (this.mipIndex == 1) {
					if (this.formLogin.mobile == '') {
						this.$message.error("手机号不能为空！")
						return false;
					}

					if (!(/^1[3456789]\d{9}$/.test(this.formLogin.mobile))) {
						this.$message.error("手机号码有误，请重新输入！")
						return false;
					}

					mobile = this.formLogin.mobile;
				} else if (this.mipIndex == 3) { //
					if (this.forgetForm.mobile == '') {
						this.$message.error("手机号不能为空！")
						return false;
					}

					if (!(/^1[3456789]\d{9}$/.test(this.forgetForm.mobile))) {
						this.$message.error("手机号码有误，请重新输入！")
						return false;
					}

					mobile = this.forgetForm.mobile;
				}

				this.$post('/http/sendMsg', {
					mobile: mobile,
					smsToken: '13082692902000'
				}).then((res) => {
					if (res) {

						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000)
						}
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			// 点击登录
			clickLogin() {
				var data;
				var url;

				if (this.tabIndex == 0) {
					if (this.form.account === '') {
						this.$message.error("账号不能为空！")
						return false;
					}
					if (this.form.password === '') {
						this.$message.error("密码不能为空！")
						return false;
					}
					data = {
						account: this.form.account,
						password: this.form.password,
					}
					url = '/index/accountLogin';
				} else {
					if (this.form.mobile === '') {
						this.$message.error("手机号不能为空！")
						return false;
					}
					if (!(/^1[3456789]\d{9}$/.test(this.form.mobile))) {
						this.$message.error("手机号有误，请重新输入！")
						return false;
					}
					if (this.form.code === '') {
						this.$message.error("验证码不能为空！")
						return false;
					}
					data = {
						mobile: this.form.mobile,
						code: this.form.code,
					}
					url = '/index/msmcodeLogin';
				}

				this.$post(url, data).then((res) => {
					if (res) {

						sessionStorage.setItem('token', res.data.token)
						sessionStorage.setItem('userId', res.data.id)
						// sessionStorage.setItem('user', JSON.stringify(res.data.user))

						this.$message.success("登录成功")
						this.$router.replace({
							path: '/index',
						})
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})

			},
			// 点击注册
			loginClick() {
				clearInterval(this.timer);
				this.show = true;
				this.count = '';
				this.timer = null;

				this.mipIndex = 1;
			},
			// 点击忘记密码
			forgetPassword() {
				clearInterval(this.timer);
				this.show = true;
				this.count = '';
				this.timer = null;

				this.mipIndex = 3;
			},
			// 点击去登录
			goEnter() {
				this.mipIndex = 0;
			},
			// 点击注册-下一步
			nextStep() {
				if (this.formLogin.mobile === '') {
					this.$message.error("手机号不能为空！")
					return false;
				}
				if (!(/^1[3456789]\d{9}$/.test(this.formLogin.mobile))) {
					this.$message.error("手机号有误，请重新输入！")
					return false;
				}
				if (this.formLogin.code === '') {
					this.$message.error("验证码不能为空！")
					return false;
				}
				if (this.formLogin.account === '') {
					this.$message.error("用户名不能为空！")
					return false;
				}
				if (this.formLogin.password === '') {
					this.$message.error("密码不能为空！")
					return false;
				}

				this.$post('/index/userRegister', this.formLogin).then((res) => {
					if (res) {
						this.mipIndex = 2;
						this.evpiForm.id = res.data;

						this.$post('/index/sysTradeList', {}).then((res) => { //行业
							if (res) {
								this.industryArray = res.data
							}
						}).catch((error) => {
							this.$message.error(error.message)
						})
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			// 选择行业
			selectChange(e) {
				console.log(e, 'eee')
			},
			// 点击忘记密码确定
			clickForgetPassword(index) {
				if (index == 0) {
					this.mipIndex = 0;
				} else {
					// 忘记密码后的登录
					if (this.forgetForm.mobile === '') {
						this.$message.error("手机号不能为空！")
						return false;
					}
					if (!(/^1[3456789]\d{9}$/.test(this.forgetForm.mobile))) {
						this.$message.error("手机号有误，请重新输入！")
						return false;
					}
					if (this.forgetForm.code === '') {
						this.$message.error("验证码不能为空！")
						return false;
					}
					if (this.forgetForm.password === '') {
						this.$message.error("密码不能为空！")
						return false;
					}
					if (this.forgetForm.password1 === '') {
						this.$message.error("新密码不能为空！")
						return false;
					}
					if (this.forgetForm.password != this.forgetForm.password1) {
						this.$message.error("两次密码输入不一致！")
						return false;
					}
					delete this.forgetForm.password1
					this.$post('/index/userResetPassword', this.forgetForm).then((res) => {
						if (res) {
							this.mipIndex = 0;
							this.$message.success("修改成功");
						}
					}).catch((error) => {
						this.$message.error(error.message)
					})

				}
			},
			// 点击完善信息返回
			clickEVPI(index) {
				if (index == 0) {
					this.mipIndex = 1;
				} else {
					// 去登录
					if (this.evpiForm.company_name == '') {
						this.$message.error("公司名称不能为空！")
						return false;
					}
					if (this.evpiForm.trade_id == '') {
						this.$message.error("行业不能为空！")
						return false;
					}
					if (this.evpiForm.contacts == '') {
						this.$message.error("联系人不能为空！")
						return false;
					}
					if (this.evpiForm.email == '') {
						this.$message.error("邮箱不能为空！")
						return false;
					}
					var reg1 = new RegExp("^\\w+@\\w{2,6}(\\.\\w{2,3})+$", "i");
					if (reg1.test(this.evpiForm.email)) {

					} else {
						this.$message.error("请输入正确的邮箱地址！")
						return false
					}

					if (this.evpiForm.address == '') {
						this.$message.error("地址不能为空！")
						return false;
					}

					this.$post('/index/userCompleteInfo', this.evpiForm).then((res) => {
						if (res) {
							this.mipIndex = 0;
							this.$message.success("已提交，待审核");
						}
					}).catch((error) => {
						this.$message.error(error.message)
					})

				}
			},
		},
		// 计算属性
		computed: {},
		// 侦听器
		watch: {},
	}
</script>

<style lang="scss" scoped>
	html,
	body,
	.login {
		width: 100vw;
		height: 100vh;
		position: relative;

		.leftBox {
			width: 640px;
			height: 100%;
			background-image: url(../assets/img/bg_z.png);
			background-repeat: no-repeat;
		}

		.texts {
			width: 300px;
			height: 116px;
			margin-left: 46px;
			margin-top: 227px;
		}

		.rightBox {
			width: calc(100vw - 640px);
			height: 100%;
			background-color: #F7F9FC;
			background-image: url(../assets/img/bg_y.png);
		}

		.nesText {
			width: 500px;
			height: 620px;
			background: #FFFFFF;
			box-shadow: 0px 0px 20px rgba(17, 130, 251, 0.05);
			opacity: 1;
			border-radius: 10px;
			margin: 170px 390px;

			.returnImg {
				width: 24px;
				height: 18px;
				margin-right: 10px;
			}

			.divBox {
				position: relative;
				width: 380px;
				height: 50px;
				margin: 0px 60px;
				// background: #F7F9FC;
				border-radius: 4px;
				margin-bottom: 30px;
				margin-top: 30px;

				.iconImg {
					position: absolute;
					top: 15px;
					left: 10px;
					width: 22px;
					height: 22px;
					z-index: 1;
				}

				.codeBox {
					font-size: 16px;
					color: #3D6EE2;
					position: absolute;
					top: 15px;
					right: 14px;

					.getCode {
						cursor: pointer;
					}
				}

				.tableTxt {
					text-align: right;
					font-size: 16px;
					color: #252630;
					width: 110px;
					padding-right: 10px;
				}
			}

			.txtBox {
				width: 380px;
				margin: 0 auto;

				.txtDiv {
					color: #3D6EE2;
					cursor: pointer;

				}
			}


			.loginIpt {
				width: 380px;
				height: 50px;
				opacity: 1;
				border-radius: 4px;
				outline: none;
				border: none;
				background: #F7F9FC !important;

			}

			.loginSelect {
				width: 290px;
			}

			/deep/.el-input__wrapper {
				background-color: #F7F9FC !important;
			}

			.tabBox {
				cursor: pointer;

				.border {
					width: 40px;
					height: 4px;
					background: #3D6EE2;
					margin-top: 16px;
				}

				.border1 {
					width: 40px;
					height: 4px;
					background: #fff;
					margin-top: 16px;
				}
			}

			.tabAdd {
				color: #252630 !important;
			}
		}

		.nesTextW {
			margin: 110px 390px;
			height: 710px;

			.tableTxtH {
				width: 82px !important;
			}

			/deep/.el-input__wrapper {
				height: 48px;
			}
		}

		.logins {
			width: 380px;
			height: 50px;
			background: #3D6EE2;
			opacity: 1;
			border-radius: 4px;
			margin-left: 60px;
			margin-top: 70px;
			color: #fff !important;
			font-size: 16px;
		}

		.btnEVPI {
			margin-top: 50px;
			margin-left: 150px;
			width: 297px;
		}

		.color9D9FA3 {
			color: #9D9FA3;
		}
	}

	p {
		padding-left: 60px;
		padding-top: 60px;
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 21px;
		color: #252630;
		// letter-spacing: 20px;
		opacity: 1;
	}

	.divP {
		padding-left: 60px;
		padding-top: 60px;
		cursor: pointer;

		p {
			padding-left: 0px;
			padding-top: 0px;
		}
	}
</style>